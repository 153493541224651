angular.module(config.app.name).component('address', {
    templateUrl: 'components/procedure/components/address.component.html',
    bindings: {
        address: '<',
        onAddressSet: '&',
        needDetails: '<',
        needResidenceDepartment: '<'
    },
    controller: function ($element, $scope, $timeout, $_procedures) {

        var self = this;
        self.deps = gData.departements;
        self.addressWayTypes = gData.addressWayTypes;

        self.address = {
            num: null,
            ext: null,
            wayType: 'rue',
            wayName: null, // "de l'avenue",
            postalCode: '12345',
            city: 'jolie ville'
        };
        self.formIsValid = false;

        self.$onInit = function () {
            console.log('init', 'info', 't::adresse')
        };

        self.$postLink = function () {
            console.log('post link', 'info', 't::adresse')
            setTimeout(() => {
                $element[0].dispatchEvent(new CustomEvent('component-load'));
            });
            console.log('>>> address: ', self.address);
            $timeout(() => {
                self.addressForm.$setSubmitted();
            }, 1000);
        };

        self.onChange = function (target) {
            if (target && target.$name === 'postalCode') {
                if (self.needResidenceDepartment && self.addressForm.city.$valid && self.addressForm.postalCode.$valid) {
                    self.getDepartmentByPostalCode(self.address.postalCode, self.address.city).then((res) => {
                        self.address.residenceDepartment = res.codeDep;
                        $timeout(() => {
                            $scope.$apply();
                        });
                    }).catch((err) => {
                        console.log('>>> ERROR :', err);
                    });
                }
            }
            self.formIsValid = self.addressForm.$valid;
            if (self.formIsValid === true) {
                self.validate();
            }
        };

        self.getDepartmentByPostalCode = function (postalCode, commune) {
            if (postalCode && commune && postalCode.length === 5 && commune !== '') {
                return $_procedures.getData('departement', 'filter[postalCode]=' + postalCode + '&filter[commune]=' + commune);
            }
            return Promise.reject('NoData');
        };

        self.validate = function () {
            self.onAddressSet({address: self.address});
        };
        self.getWayType = (query) => {
            return query ? self.addressWayTypes.filter(createFilterFor(query)) : self.addressWayTypes;
        };
        self.selectedWayTypeChange = (item = null) => {
            if (item !== null && typeof item !== 'string') {
                self.address.wayType = item.short;
            }//
        };
    }
}
);